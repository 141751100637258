import axios from "axios";
import Utility from "../../shared/utility";
import { store } from "../../state-management/state-mgmt";
export default {


  getBtoListQuickSearch(call,userId,searchKey){
  return  this.getData(`wc/bto_quick_search?user_id=${userId}&search=${searchKey}`, call)
  },
  getBtoListDropDown(call,obj,showMessage){
    return  this.getData(`wc/bto_inprocess_queue_dropdowns`, call, obj, showMessage)
    },
  getBtoFilterSearch(call,obj,showMessage){
    return  this.getData(`wc/bto_inprocess_queue_load`, call, obj, showMessage)
    },
  getBtoDetails(call,obj,showMessage){
     return this.getData(`wc/bto_kits_get_bono`, call, obj, showMessage)
    },
  postBtoDeviceChange(call,obj,showMessage){    
     return this.getData(`wc/bto_kits_update_wsname`, call, obj, showMessage)
    },
  postBtoSave(call,obj,showMessage){
    return this.getData(`wc/bto_kits_config_update`, call, obj, showMessage)
  },

  postBtoConfigComplete(call,obj,showMessage){
    return this.getData(`wc/bto_kits_config_complete`, call, obj, showMessage)
  },
  postValidatdeUnit(call,obj,showMessage){
    return this.getData(`wc/bto_kits_marry_bcn`, call, obj, showMessage)
  },
  postLoadComplete(call,obj,showMessage){
    return this.getData(`wc/bto_kits_load_complete`, call, obj, showMessage)
  },
  getShowKeysTable(call,line_key,kitKey){
    return  this.getData(`wc/bto_kits_get_load_keys?line_key=${line_key}&kit_key=${kitKey}`, call)
  },
  loadAddKey(call,line_key,kitKey){
    return  this.getData(`wc/bto_kits_get_temp_keys?line_key=${line_key}&kit_key=${kitKey}`, call)
  },
  loadAddKeyDelete(call,obj,showMessage){
    return this.getData(`wc/bto_kits_delete_temp_key`, call, obj, showMessage)
  },
  postLicenceSave(call,obj,showMessage){
    return this.getData(`wc/bto_kits_save_temp_key`, call, obj, showMessage)
  },
  getUnassignData(call,userId,bono){
    return  this.getData(`wc/bto_kits_unassignments?userid=${userId}&bono=${bono}`, call)
    },

    getBtoStagingLpnCheck(call,obj,showMessage){
      return this.getData(`wc/bto_kits_val_lpn_loc`, call, obj, showMessage)
    },

    postBtoTempLoadSave(call,obj,showMessage){
      return this.getData(`wc/bto_kits_save_temp_load`, call, obj, showMessage)
    },
  
  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error?.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error?.response?.data?.message ,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res?.data?.body?.message != undefined || res?.data?.body?.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res?.data?.body?.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: this.isJson(res?.data?.body?.message) ? JSON.parse(res?.data?.body?.message).Message : "" || res?.data?.body?.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        if (this.isJson(res?.data?.body?.message))
          return JSON.parse(res?.data?.body?.message) 
        else
          return `{"Status":200,"Message":"${res?.data?.body?.message }"}`
            ;
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else if (res?.data?.body?.message == null) {
      if (res?.data?.body !== null) {
        LoaderDialog.visible = false;
        return res?.data?.body;
      } else {
        LoaderDialog.visible = false;
        return JSON.parse('{"message":"NA"}');
      }
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res?.data.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res?.data?.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        return JSON.parse(res?.data?.message) 
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};

      }
    }


  },
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

};
